//import React, {useRef, useEffect, useState} from "https://cdn.skypack.dev/react@17.0.1";
//import ReactDOM from "https://cdn.skypack.dev/react-dom@17.0.1";
import React, {useRef, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div className='container d-flex justify-content-center p-1'>
      <Clock25Plus5 />
    </div>
  );
}

function Clock25Plus5() {
    const [isReset, setIsReset] = useState(true);
    const [isRunning, setIsRunning] = useState(false);
    const [label, setLabel] = useState('SESSION'); 
    const [startStopLabel, setStartStopLabel] = useState('Start'); 
    const [breakLength, setBreakLength] = useState(5);
    const [sessionLength, setSessionLength] = useState(25);
    const [seconds, setSeconds] = useState(25*60);
    const [timer, setTimer] = useState(null);

    const buzzerRef = useRef();

    useEffect(() => {
        console.log('useEffect()[isRunning] isRunning: '+isRunning);
        if(isRunning == true){
            setIsReset(false);

            const intervalId = setInterval(function() {
                setSeconds(seconds => seconds-1); 
            }, 1000);
            setTimer(intervalId);
            return () => {
                console.log('clearInterval invoked');
                clearInterval(intervalId); 
            }        
        }
    }, [isRunning]);

    useEffect(() => {
        if(isRunning == false){
            clearInterval(timer);
        }
    }, [isRunning, timer]);

    useEffect(() => {
        if(isRunning == true){
            setStartStopLabel('Pause');         
        }
        else if(isRunning == false && isReset == false){
            setStartStopLabel('Resume');
        }
    }, [isRunning, isReset]);
  
    useEffect(() => {
        console.log('Clock25Plus5 useEffect()[seconds] seconds: '+seconds);
        if(seconds < 0){
            buzzerRef.current.play();
            if(label == 'SESSION'){
                setLabel('BREAK');
                setSeconds(breakLength*60);
            }
            else{
                setLabel('SESSION');
                setSeconds(sessionLength*60);
            }
        }
    },[seconds]);

    useEffect(() => {
        if(isReset == true){
            setSeconds(sessionLength*60);
        }
    }, [sessionLength, isReset]);

    const reset = () => {  
        buzzerRef.current.pause();
        buzzerRef.current.currentTime = 0; 
        setIsRunning(false);
        setStartStopLabel('Start');
        setSessionLength(25);
        setBreakLength(5);
        setSeconds(25*60);
        setLabel('SESSION');
        setIsReset(true);
    }

    String.prototype.toMMSS = function () {
        var sec_num = parseInt(this, 10); // don't forget the second param
        var minutes = Math.floor((sec_num ) / 60);
        var seconds = sec_num - (minutes * 60);

        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds;
    }

    return (
        <div id='Clock25Plus5' className='bg-dark pt-0 pb-3 px-3 rounded'>
        <div className='row'>
            <div className='col'>
            <Session label={label} seconds={seconds} start_stop={() => setIsRunning(isRunning => !isRunning)} startStopLabel={startStopLabel} isRunning={isRunning} reset={reset} isReset={isReset} />
            </div>        
        </div>
        <div className='d-flex flex-nowrap'>
            <SessionLength callback={setSessionLength} value={sessionLength} />
            <BreakLength callback={setBreakLength} value={breakLength} />           
        </div>
        <audio ref={buzzerRef} id='beep'>
            <source src="http://255clock.bluegunn.com/alarm.wav" type="audio/wav" />
            Your browser does not support the audio tag.
        </audio>
        </div>
    );
}

function BreakLength(props) {
  const increase = () => {
    if(props.value+1 <= 60) {
      props.callback(props.value+1);  
    }
  }
  
  const decrease = () => {
    if(props.value-1 > 0){
     props.callback(props.value-1); 
    }
  }
  
  return (
        <fieldset className='pt-0 pb-3 px-3'>
        <legend id='break-label' className='text-light'>Break Length</legend>
      <h3 id='break-length' className='d-flex justify-content-center text-dark bg-light rounded'>{props.value}</h3>
      <div className='row'>
        <div className='col d-grid'><button id='break-increment' className='btn btn-light' onClick={increase}>Increase</button></div>
          <div className='col d-grid'><button id='break-decrement' className='btn btn-light' onClick={decrease}>Decrease</button></div>
      </div>
        </fieldset>    
  );
}

function SessionLength(props) {
  const increase = () => {
    if(props.value+1 <= 60) {
      props.callback(props.value+1);  
    }
  }
  
  const decrease = () => {
    if(props.value-1 > 0){
     props.callback(props.value-1); 
    }
  }
  
  return (
      <fieldset className='pt-0 pb-3 px-3'>
        <legend id='session-label' className='text-light'>Session Length</legend><h3 id='session-length' className='d-flex justify-content-center text-dark bg-light rounded'>{props.value}</h3>
        <div className='row'>
          <div className='col d-grid'><button id='session-increment' className='btn btn-light' onClick={increase}>Increase</button></div>
          <div className='col d-grid'><button id='session-decrement' className='btn btn-light' onClick={decrease}>Decrease</button></div>
      </div>
      </fieldset>    
  );
}

function Session(props){
    console.log('Session() props.seconds: '+props.seconds);
    let seconds = props.seconds >= 0 ? props.seconds : 0;
    let display = seconds.toString().toMMSS(); 

    return (
            <fieldset className='pt-0 pb-3 px-3'>
                <legend className='text-light'>Timer</legend>
                <h4 id='timer-label' className='d-flex justify-content-center text-dark bg-light rounded'>{props.label}</h4>
                <h1 id='time-left' className='d-flex justify-content-center text-dark bg-light rounded'>{display}</h1>
                <div className='row'>
                    <div className='col d-grid'>
                        <button id='start_stop' className='btn btn-lg btn-light' onClick={props.start_stop}>{props.startStopLabel}</button>
                    </div>
                    <div className='col d-grid'>
                        <button id='reset' className='btn btn-lg btn-light' onClick={props.reset}>Reset</button>
                    </div>
                </div>
            </fieldset>    
    );
}

const doc = document.querySelector("#root");
ReactDOM.render(<App />, doc);